.page-wrapper {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.words-form {
  text-align: left;
  margin-bottom: 30px;
}

.words-input {
  min-width: 100%;
  margin-bottom: 10px;
  border-width: 1px;
  border-color: #333;
  border-radius: 3px;
  box-shadow: none;
  border-style: solid;
}

.input-for-inline {
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

.input-for-inline input { cursor: inherit; }

@media (prefers-color-scheme: dark) {
  .words-input {
    color: #eaeaea;
    background-color: #0C0D0E;
  }
}