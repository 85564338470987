.page-wrapper {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

@media (prefers-color-scheme: dark) {
    a {
      color: #4F4FB0;
    }
  }