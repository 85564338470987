.page-made-by {}

.page-made-by a {
  color: #e07d13;
  text-decoration: none;
}

.page-made-by a:hover { text-decoration: underline; }

.heart-ascii {
  color: red;
  color: #ce2029;
  font-family: consolas, "Liberation Mono", courier, monospace;
}
